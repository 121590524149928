<template>
  <div class="{'blur-content': showModal}">
    <!-- <div class="home-card-container"> -->
    <div outlined class="home-card">
      <div class="content-container">
        <div class="col masthead">
          <div class="bg-circle-1 bg-circle"></div>
          <div class="bg-circle-2 bg-circle"></div>
          <div class="bg-circle-3 bg-circle"></div>
          <div class="bg-circle-4 bg-circle"></div>
          <div class="log"><vs-button @click="logout()" v-if="checkname == true" color="green">登出</vs-button></div>
          <PritiNavbar :value="show_dialog" @return_value="getname" v-if="false" />
          <!-- <div v-if="newName==''" style="font-size:30px">請先登入</div>
            <div v-else style="font-size:30px"> {{ newName }} 您好</div> -->
          <v-card style="padding:10%" v-if="checkname == false">
            <h3 class="login-text">登入</h3>
            <v-text-field placeholder="輸入帳號" outlined v-model="account" type="text">
            </v-text-field>
            <v-btn block large color="green" class="login-btn" @click="login()" :disabled="login_submit">
              <span class="color-fff">送出 ></span>
            </v-btn>
            <v-progress-circular indeterminate color="primary" v-if="login_submit"></v-progress-circular>
          </v-card>
          <div v-if="checkname == true">
            <div style="font-size:1.9em;position: relative;"> 使用者名稱 : {{ newName }}</div>
            <div style="font-size:1.9em;position: relative;"> 標籤ＩＤ : {{ tag_path }}</div>
            <div style="font-size:1.9em;position: relative;"> 標籤名稱 : {{ tag_name }}</div>
            <div style="font-size:1.9em;position: relative;display: flex;"> 備註：
              <!-- <input id="remark" type="text" :placeholder="remark" style="font-size:30px;position: absolute;color:beige"> -->

              <textarea class="textbox1"
                style="font-size:0.7em;background-color: aliceblue;width:10rem;top:0.1rem;height:2.8rem; display: flex;"
                v-model="remark" placeholder="備註"></textarea>
            </div>

            <vs-button class="" role="button" aria-expanded="false" type="button" @click="pushtag()" color="black" dark
              style="font-size:1em">送出</vs-button>
            <div v-if="dialog"> <!-- @click.stop="dialog = false" -->
              <div class="" style="background: rgba(248,249,250,.75);
                                                bottom: 0;
                                                cursor: default;
                                                display: block;
                                                left: 0;
                                                position: absolute;
                                                right: 0;
                                                top: 0;"></div>
              <v-card v-model="dialog" max-width="290" style="z-index: 1;
                                                  position: absolute;
                                                  width: 18rem;
                                                  bottom: 27%;">
                <v-card-title class="text-h5">
                  <p>「護聯資訊通知」</p>
                  <p>狀態：{{ newName }}打卡成功</p>
                </v-card-title>
                <v-card-text class="text-h6">
                  <p>地點：{{ tag_name }}</p>
                  <p style="display:flex;">備註：<span style="white-space: pre-wrap;">{{ remark }}</span></p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </div>
            <!-- <v-btn
                color="primary"
                dark
                @click.stop="dialog = true"
              >
                Open Dialog
              </v-btn> -->
            <!-- <v-card v-model="dialog" max-width="290" v-if="dialog" style="" >
                <v-card-title class="text-h5">
                    Use Google's location service?
                  </v-card-title>
                  <v-card-text>
                    Let Google help apps determine lo
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false">
                      Agree
                    </v-btn>
                  </v-card-actions>
              </v-card> -->

          </div>
        </div>

      </div>
    </div>
    <!-- <v-dialog v-model="dialog" max-width="290" style="z-index:300; opacity: 1;display:contents" >
                <v-card style="z-index:300; opacity: 1;">
                  <v-card-title class="text-h5">
                    Use Google's location service?
                  </v-card-title>
                  <v-card-text>
                    Let Google help apps determine lo
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false">
                      Agree
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
    <!-- <button type="button" @click="changeName()">123</button> -->
  </div>
</template>

<script>
import PritiNavbar from "../components/PritiNavbar.vue";
// import Footer from "../components/Footer.vue";

import axios from "axios";
export default {
  data() {
    return {
      unit: '111',
      newName: '',
      show_dialog: false,
      tag_path: '',
      checkname: false,
      account: '',
      login_submit: false,
      tag_list: [],
      tag_name: '',
      remark: '',
      token: '',
      dialog: false,
    };
  },
  created() {
    console.log('router', this.$route.query)
  },
  mounted() {
    let _self = this
    // 取parameter
    _self.tag_path = _self.$route.query.tag
    _self.unit = _self.$route.query.unit
    // console.log('localStorage.name',localStorage.name)
    // check 是否已登入
    if (localStorage.name != undefined && localStorage.name != '') {
      _self.newName = localStorage.name
      _self.checkname = true
      console.log('check localStorage name ok')
    } else {
      _self.checkname = false
      _self.name = localStorage.name;
    }
    console.log(localStorage.name, _self.tag_path, _self.unit)
    this.gettaglist()
  },
  // computed:{
  //   newName (){
  //     return localStorage.name?localStorage.name:''
  //   },
  // },
  watch: {
    newName(newName) {
      localStorage.name = newName;
    },
    show_dialog() {
      console.log('show_dialog')
    },

  },
  methods: {
    changeName() {
      this.newName = localStorage.name
      console.log(123321)
    },
    getname(element) {
      if (element)
        this.newName = localStorage.name
      console.log('ele', element);
    },
    async login() {
      // this.localStorage.setItem("phoneType",123);
      this.login_submit = true

      let _self = this
      console.log('login', _self.account)
      // 模擬lag
      // await setTimeout(function(){
      if (_self.account != '') {
        axios.post('http://hciot.com.tw:7800/api/nfc/checkin/account',
          {
            "DB": _self.unit,
            "account": _self.account
          }
        ).then(function (res) {
          console.log(res)
          if (res.data == 'err' || res.status != 200) {
            _self.login_submit = false
            const options = { title: '', size: 'sm' }
            _self.$dialogs.alert('帳號錯誤請重試', options)
              .then(res => {
                console.log(res) // {ok: true|false|undefined}
              })
          }
          else {
            localStorage.name = res.data[0].cname;
            _self.newName = res.data[0].cname
            localStorage.account = res.data[0].account;
            localStorage.id = res.data[0].id;
            // _self.dialog=false
            // _self.$emit('return_value',true);
            _self.checkname = true
          }

        })
      } else {
        _self.login_submit = false
        const options = { title: '', size: 'sm' }
        _self.$dialogs.alert('帳號錯誤請重試', options)
          .then(res => {
            console.log(res) // {ok: true|false|undefined}
          })
      }

      // }, 1000);
    },
    logout() {
      localStorage.clear();
      console.log('logout')
      this.login_submit = false
      this.checkname = false
    },
    pushtag() {
      let _self = this
      // check 是否有tag_id
      console.log('cid', localStorage.id)
      console.log('lid', _self.tag_path)
      if (_self.tag_path != '') {
        axios.post('http://hciot.com.tw:7800/api/nfc/checkin/insert',
          {
            "DB": _self.unit,
            "lid": _self.tag_path,
            "lname": _self.tag_name,
            "cid": localStorage.id,
            "cname": localStorage.name,
            "remark": _self.remark
          }
        ).then(function (res) {
          console.log('checkin/insert', res)
          if (res.data == 'ok') {
            _self.pushNotify()
            _self.dialog = true
          }
        })
      }
    },
    gettaglist() {
      let _self = this
      console.log(_self.tag_path)
      axios.post('http://hciot.com.tw:7800/api/nfc/checkin/select_tag',
        {
          "DB": _self.unit
        }
      ).then(function (res) {
        console.log(res)
        _self.tag_list = res.data
        _self.tag_name = _self.tag_list.find(el => el.tag_id === _self.tag_path).tag_name
        console.log('temp', _self.tag_name)
      })
    },
    pushNotify() {
      let _self = this
      axios.post("http://hciot.com.tw:7800/api/nfc/checkin/notify",
        {
          "DB": _self.unit,
          "message": "\n「打卡通知」\n狀態：" + localStorage.name + "打卡成功\n地點：" + _self.tag_name + "\n備註：" + _self.remark
        })
        .then((response) => {
          console.log(response);
        });
    }
  },
  name: "Home",

  components: {
    PritiNavbar,
    // Footer,
  },
};
</script>
<style scoped>
.logo {
  text-shadow: #000000;
  font-size: 48px;
  font-weight: bold;
  transform: translateY(-50%);
  padding: 20px 20px;
  height: max-content;
  position: absolute;
  left: 40px;
  top: 30px;
}

.log {
  transform: translateY(-50%);
  height: max-content;
  position: absolute;
  right: 10px;
  top: 30px;
}

.home-card-container {
  background-color: #0093e9;
  background-image: linear-gradient(180deg,
      #0093e9 0%,
      #0093e9 50%,
      #80d0c7 100%);

  /* padding: 0px 40px; */
  height: 100%;
  width: 100%;
}

.home-card {
  height: 100vh;
  background-color: #fafafa;
}

.content-container {
  display: grid;
  height: 100%;
  /* grid-template-rows: 80% 20%; */
}

.col1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-image: url("../assets/home.jpg"); */
  background-color: #000000;
  width: 100%;
  background-size: cover;
  color: white;
}

.search-bar {
  display: grid;
  grid-template-columns: 80% 20%;
  width: 40%;
  align-items: center;
  padding: 8px;
}

.col2 {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.col2-divs {
  padding: 10px;
}

.col2-reviews {
  display: grid;
  place-items: center;
}

.booking {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.column {
  margin: 10px;
  display: grid;
  place-items: center;
  text-align: center;
}

.feature-image {
  height: 200px;
}

.feature-container {
  width: 100%;
  min-width: 100%;
  padding: 20px 80px;
}

.add-property {
  text-align: center;
  padding: 80px;
  background-color: #fafafa;
}

.masthead {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-image: url("../assets/home.jpg"); */
  background-color: #000000;
  width: 100%;
  color: white;
  position: relative;
  overflow: hidden;
  padding-top: calc(7rem + 72px);
  padding-bottom: 7rem;
  background: linear-gradient(0deg, #ff6a00 0%, #ee0979 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
}

.masthead .masthead-content {
  z-index: 1;
  position: relative;
}

.masthead .masthead-content .masthead-heading {
  font-size: 4rem;
}

.masthead .masthead-content .masthead-subheading {
  font-size: 2rem;
}

.masthead .bg-circle {
  z-index: 0;
  position: absolute;
  border-radius: 100%;
  background: linear-gradient(0deg, #ee0979 0%, #ff6a00 100%);
}

.masthead .bg-circle-1 {
  height: 90rem;
  width: 90rem;
  bottom: -55rem;
  left: -55rem;
}

.masthead .bg-circle-2 {
  height: 50rem;
  width: 50rem;
  top: -25rem;
  right: -25rem;
}

.masthead .bg-circle-3 {
  height: 20rem;
  width: 20rem;
  bottom: -10rem;
  right: 5%;
}

.masthead .bg-circle-4 {
  height: 25rem;
  width: 25rem;
  top: -5rem;
  right: 45%;
}

@media (min-width: 992px) {
  .masthead {
    padding-top: calc(10rem + 55px);
    padding-bottom: 10rem;
  }

  .masthead .masthead-content .masthead-heading {
    font-size: 6rem;
  }

  .masthead .masthead-content .masthead-subheading {
    font-size: 4rem;
  }

  .v-progress-circular {
    position: absolute;
    left: 50%;
    top: 35%;
    z-index: 1000;
    height: 31px;
    width: 31px;
  }

  .textbox1 {
    BACKGROUND: #BFCEDC;
    BORDER-TOP: #7F9DB9 1px solid;
    BORDER-LEFT: #7F9DB9 1px solid;
    BORDER-RIGHT: #7F9DB9 1px solid;
    BORDER-BOTTOM: #7F9DB9 1px solid;
    FONT-FAMILY: "宋體", "Verdana", "Arial", "Helvetica";
    FONT-SIZE: 12px;
    TEXT-ALIGN: LIFT;
  }
}
</style>
