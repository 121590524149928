<template>
  <div class="navbar ">
    <vs-card>
      <template #text>
        <div style="display:flex; margin:-15px;">
          <!-- <vs-button shadow color="#000" href="/" >Home</vs-button>
          <vs-button shadow color="black" href="#features" >Features</vs-button>
          <vs-button shadow color="black">How it works</vs-button>
          <vs-button shadow color="black" @click="toPage ">Help</vs-button> -->


          <!-- <vs-button shadow color="black"> -->
            

          <v-dialog v-model="dialog" width="400" class="login-dialog">
            <template v-slot:activator="{ on, attrs }">
              <vs-button color="#fff" dark v-bind="attrs" v-on="on">
                <span class="color-000"> 登出</span>
              </vs-button>
            </template>

            <div class="flex">
              <!-- <div class="body-image item">
                <img
                  src="https://static-assets-amberstudent.imgix.net/images/login/yuccasofa.jpg"
                  alt=""
                />
              </div> -->
              <div class="body-main item">
                <div>
                  <h3 class="login-text">請輸入帳號</h3>
                  <v-text-field
                    placeholder="Enter Your Account"
                    outlined
                    v-model="account"
                    type="text"
                  >
                  </v-text-field>
                  <v-btn block large color="green" class="login-btn" @click="login()">
                    <span class="color-fff">送出 ></span>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-dialog>
        </div>
      </template>
    </vs-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      account:''
    }
    
  },
  // props:['close1'],
  methods: {
    toPage: function(){
      // _self.$router.push({ name: 'help' });
    },
    login(){
      // this.localStorage.setItem("phoneType",123);
      let _self= this
      console.log('login',_self.account)
      if(_self.account!='')
      axios.post('http://hciot.com.tw:7800/api/nfc/checkin/account', 
        {
          "DB": "ihc_demo1",
          "account": _self.account
        }
      ).then(function (res) {
        // _self.$emit('show_dialog',false);
        // console.log('emit',_self.$emit)
        console.log(res)
        if(res.data=='err_act'||res.status!=200){
          alert('帳號錯誤請重試')
        }
        else{
          alert('登入成功，'+res.data[0].cname+'你好')
          localStorage.name = res.data[0].cname;
          localStorage.account = res.data[0].account;
          _self.dialog=false
          _self.$emit('return_value',true);
        }

      })
    }
  }
}
</script>

<style scoped>
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 20px 20px;
  transform: translateY(-50%);
  height: max-content;
  position: fixed;
  right: 40px;
  top: 50px;
}

.flex {
  display: flex;
  flex-direction: row;
  /* margin: 150px 200px; */
  border-radius: 10px;
  flex: 50%;
}
.body-main {
  background-color: hsl(0, 0%, 100%);
  width: 10%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  flex: 20%;
  padding: 5px;
}
.body-image {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: hsl(0, 0%, 100%);
  flex: 50%;
  width: 50%;
}
img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  flex: 50%;
}
.login-dialog {
  filter: blur(10px);
}

.login-text {
  margin: 25px 0px;
}
.login-btn {
  margin-bottom: 20px;
}
.color-fff {
  color: #fff;
}
.tnc {
  font-size: 10px;
}
.color-000 {
  color: #000;
}
</style>
